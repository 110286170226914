import { connect } from 'react-redux';
import { State } from 'store/types';
import View from './View';
import { fetchSearchSuggestion, updateSearchField, showScreen } from 'store/searchSuggestions/action';


const mapDispatchToProps = {
  fetchSearchSuggestion,
  updateSearchField,
  initializeScreen: showScreen,
};

const mapStateToProps = (state: State) => {
  const { appReducer, remoteConfig, auth } = state;

  return {
    pageType: appReducer.pageType,
    maxSearchHistoryCount: remoteConfig.configs.maxSearchHistoryCount,
    isPro: auth.user.isPro,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
