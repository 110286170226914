import React from 'react';
import styled from 'App/styled';


const Wrapper = styled.div`
  position: relative;
  width: 100%;

  .rightDiv {
    width: 45%;
  }
`;
const DummyWrapper = styled.div`
  height: 40px;
`;
const View = ({ markup }: any) => {
  if (!markup) {
   return (
     <DummyWrapper />
   );
  }
  return (
    <Wrapper id="offer-banner" dangerouslySetInnerHTML={{ __html: markup }} />
  );
};

export default View;
