import { logger } from '@nykaa/logger';
import { ApiHelper } from '@nykaa/utils/network';
import { ROUTES, getUrl } from 'shared/api/urls';
import { queryStringFromParams } from 'shared/helpers/utils/url';

import { transformTrendingSearches, transformSearchSuggestions, sortSearchSuggestions } from './transformer';


export const getSearchSuggestions = async (searchInput: string, isPro: boolean) => {
  const url = getUrl(ROUTES.SEARCH_SUGGESTIONS);

  try {
    const params = {
      q: searchInput,
    };
    let otherParams = {};
    if (isPro) {
      otherParams.pro = isPro;
    }
    const queryString = queryStringFromParams({ ...params, ...otherParams });
    const { data: { suggestions } } = await ApiHelper(`${url}${queryString}`, 'get');

    if (!suggestions) {
      return Promise.reject({
        message: '"suggestions" not found in api -> data',
      });
    }

    const transFormedResponse = transformSearchSuggestions(suggestions);
    return {
      suggestions: sortSearchSuggestions(transFormedResponse),
      searchInput,
    };
  } catch (e) {
    logger.error(e, 'Error in Search suggestions API');
    throw e;
  }
};

export const getTrendingSearches = async () => {
  const url = getUrl(ROUTES.TRENDING_SEARCHES);

  try {
    const { data: { response } } = await ApiHelper(`${url}`, 'get');
    const { trending } = response;
    if (!trending) {
      return Promise.reject({
        message: '"trending" searches not found in api -> data',
      });
    }

    return transformTrendingSearches(trending);
  } catch (e) {
    logger.error(e, 'Error in Trending search API');
    throw e;
  }
};
