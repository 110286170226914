import React from 'react';
import styled from 'App/styled';
import Modal from 'shared/components/Modal';


const Iframe = styled.iframe`
  height: 100vh;
  width: 400px;
  
`;


const Component = () => (
  <Modal modalBackground="transparent" onHide={() => {}}>
    <Iframe src="/mobileCartIframe?ptype=customIframeCart" />
  </Modal>

  );
export default Component;
