import { connect } from 'react-redux';
import { State } from 'store/types';
import { fetchDesktopNavigation } from '@nykaa/navigation/store/action';
import Component from './View';

const mapStateToProps = (state: State) => {
  const { navigation } = state;
  return {
    navigationData: navigation.menu,
  };
};

const mapDispatchToProps = {
  fetchNavigation: fetchDesktopNavigation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
