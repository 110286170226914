import { TrendingSearch, SearchSuggestion } from 'types/searchSuggestion';
import { getSearchHistory } from 'helpers/utils/search/searchSuggestion';
import { searchTypes } from 'constants/searchSuggestion';


interface SearchSuggestionFromAPI {
  url: string;
  id: string;
  q: string;
  type: string;
  click?: number;
  ctr?: number;
  score?: number;
  sss?: number;
  image_base?: string;
  image?: string;
  icon: string;
  subtext: string;
}

export const transformTrendingSearches = (trendingSearch: TrendingSearch[]): TrendingSearch[] => trendingSearch;

export const transformSearchSuggestions = (searchSuggestion: SearchSuggestionFromAPI[]): SearchSuggestion[] => (
  searchSuggestion.map((suggestionObj: SearchSuggestionFromAPI) => {
    let { image_base: imageBase, ...rest } = suggestionObj;
    return { ...rest, imageBase };
  })
);

export const sortSearchSuggestions = (searchSuggestion: SearchSuggestion[]) => {
  const searchItems = getSearchHistory();
  const suggestions: SearchSuggestion[] = [];
  const matchSuggestions: SearchSuggestion[] = [];
  searchSuggestion.forEach((suggestion) => {
  if ( searchItems?.some((item => (item.q?.toLowerCase() === suggestion.q?.toLowerCase())))) {
    suggestion.icon = searchTypes.HISTORY;
    matchSuggestions.push(suggestion);
  } else {
    suggestions.push(suggestion);
  }
  });
  return [...matchSuggestions, ...suggestions];
};

