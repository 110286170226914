import React from 'react';
import styled from 'App/styled';
import { getSearchHistory } from 'helpers/utils/search/searchSuggestion';
import { SearchSuggestion } from 'types/searchSuggestion';
import { searchTypes } from 'constants/searchSuggestion';

const Wrapper = styled.ul`
  background: #fff;
  z-index: 8;
`;

const Item = styled.li`
  text-decoration: none;
  color: ${(props) => props.theme.darkGrey};
  &:active {
    background: #e6e9eb;
  }
  padding: 9px 9px 9px 12px;
  display: flex;
  flex-direction: row;
  height: 37px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.brandPrimaryRgba(0.08)};
  }
`;

const Icon = styled.span`
  width: 24px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  img {
    width: 24px;
    margin-right: 10px;
    max-width: unset;
  }
`;

const Text = styled.span`
  color: #001325;

  ${({ theme }) => theme.typography.bodyMedium};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface Props {
  handleClick: (suggestion: SearchSuggestion, position: number) => void;
  searchSuggestionIcons: { history: string };
}

function History({ handleClick, searchSuggestionIcons }: Props) {
  let recentItems: SearchSuggestion[] = [];
  const searchItems = getSearchHistory();
  const { history } = searchSuggestionIcons;

  recentItems = searchItems && searchItems.length > 0 ? searchItems : [];

  const clickHandler = (
    searchSuggestion: SearchSuggestion,
    position: number
  ) => {
    searchSuggestion.listItemType = searchTypes.HISTORY;
    const searchItem = {
      ...searchSuggestion,
      id: position?.toString(),
    };

    handleClick(searchItem, position);
  };

  return recentItems && recentItems.length > 0 ? (
    <Wrapper>
      {recentItems.map((recentItem, index) => {
        const { q } = recentItem;
        return (
          <Item
            className="suggestionQuery"
            data-value={q}
            key={q}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              clickHandler(recentItem, index + 1);
            }}
          >
            <Icon>
              <img
                src={history}
                alt="clock icon"
              />
            </Icon>
            <Text>{q}</Text>
          </Item>
        );
      })}
    </Wrapper>
  ) : null;
}

export default History;
