import React from 'react';
import styled from 'App/styled';
import Bar from './Bar';


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  height: 60px;
  z-index: 1;

  &.active {
    width: 375px;
  }
`;

const Header = ({ focused }: any) => (
  <Wrapper className ={focused ? 'active' : ''}>
    <Bar customClass={focused ? 'active' : ''} />
  </Wrapper>
  );

export default Header;
