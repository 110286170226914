import React, { useState, useCallback, useEffect } from 'react';
import styled from 'App/styled';
import { logger } from '@nykaa/logger';

import {
  handleCartNotificationOnclick,
  addWindowHandler,
  GamoogaNotificationProps,
} from './helpers';


const Container = styled.span`
  display: inline-block;
  width: 120px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
  background-color: rgba(0, 0, 0, .7);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  top: 30px;
  left: -13px;
  margin-left: -32px;
  ${({ theme }) => theme.typography.bodySmall};
  padding: 5px;

  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 52px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, .7) transparent;
  }
`;

export const useGamoogaData = () => {
  const [gamoogaNotification, setGamoogaNotification] = useState(null);
  const addHandler = useCallback(() => {
    addWindowHandler((params) => {
      try {
        setGamoogaNotification(params);
      } catch (error) {
        logger.error(error, 'error in gamooga call back');
      }
    });
  }, []);
  useEffect(() => {
      addHandler();
  }, [addHandler]);
  return gamoogaNotification;
};

interface Props {
  gamoogaCartNotification: GamoogaNotificationProps | null;
};

const GamoogaNotification = ( { gamoogaCartNotification } : Props) => {
  const text = gamoogaCartNotification?.str;
  const showText = (text) && (text.length > 0);
  return (
    <>
      {showText && (
        <Container
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleCartNotificationOnclick(gamoogaCartNotification);
          }}
        >
          {text}
        </Container>
      )}
    </>
  );
};

export default GamoogaNotification;
