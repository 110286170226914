import { Dispatch } from 'redux';

import actions from './actionTypes';
import { getTrendingSearches, getSearchSuggestions } from './service';
import { logger } from '@nykaa/logger';


export const fetchSearchSuggestion = (searchTerm:string, isPro: boolean) => (dispatch: Dispatch) => {
  if (searchTerm) {
    return getSearchSuggestions(searchTerm, isPro)
      .then(res => {
        const { searchInput, suggestions } = res;
        dispatch({
          type: actions.FETCH_SEARCH_SUGGESTION,
          payload: suggestions,
          searchInput,
        });
      })
      .catch(e => {
       logger.error(e, 'Error in fetch search suggestions');
        throw e;
      });
  } else {
    return new Promise((resolve) => {
      dispatch({
        type: actions.FETCH_SEARCH_SUGGESTION,
        payload: [],
      });
      resolve();
    });
  }
};

export const fetchTrendingSearches = ( ) => (dispatch: Dispatch) => getTrendingSearches()
  .then(res => {
    dispatch({
      type: actions.FETCH_TRENDING_SEARCHES,
      payload: res,
    });
  })
  .catch(e => {
    // TODO: LOG ME
    throw e;
  });


export const updateSearchField = (payload: string = '') => (dispatch: Dispatch) => {
  dispatch({
    type: actions.UPDATE_SEARCH_FIELD,
    payload,
  });
};

export const resetSearch = () => (dispatch: Dispatch) => {
  dispatch({
    type: actions.RESET_SEARCH,
  });
};

export const showScreen = (payload) => (dispatch: Dispatch) => {
  dispatch({
    type: actions.SHOW_SCREEN,
    payload,
  });
};

export const resetSuggestions = (dispatch: Dispatch) => {
  dispatch({
    type: actions.FETCH_SEARCH_SUGGESTION,
    payload: [],
    searchInput: '',
  });
};
