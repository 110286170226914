import { connect } from 'react-redux';
import { State } from 'store/types';
import View from './View';


const mapStateToProps = (state: State) => {
  const { appReducer, remoteConfig } = state;
  return {
    storeId: appReducer.storeId,
    storeOptions: remoteConfig.configs.storeOptions,
  };
};

export default connect(mapStateToProps)(View);

