import React from 'react';
import styled from '@emotion/styled';

import PinkBoxIcon from 'components/Icons/PinkBox';
import { redirectAuthPage } from 'shared/helpers/auth';
import { pinkActionBoxTracking as pinkBoxActionTracking } from '@nykaa/prices-revealed-shared/utils';
import { PinkBoxEvents, PinkBoxEventSources } from '@nykaa/prices-revealed-shared/constants';


const PinkBoxLink = styled.button`
  background: transparent;
  border: none;
  padding: 0 4px;
  cursor: pointer;

  &:active {
    outline: none;
  }
`;

interface Props {
  active: boolean;
  pinkBoxUrl?: string;
  email: string;
}

function View({
  active,
  pinkBoxUrl,
  email,
}: Props) {
  if (active && pinkBoxUrl) {
    const handleClick = (event: React.MouseEvent) => {
      event.preventDefault();
      pinkBoxActionTracking({
        action: PinkBoxEvents.GO_TO_PINK_BOX,
        pageSource: PinkBoxEventSources.HEADER,
      });

      setTimeout(() => {
        if (email) {
          window.location.href = pinkBoxUrl;
        } else {
          redirectAuthPage();
        }
      }, 150);
    };

    return (
      <PinkBoxLink type="button" onClick={handleClick}>
        <PinkBoxIcon />
      </PinkBoxLink>
    );
  }

  return null;
}

export default View;
