export const NAV = {
    categories: {
      title: 'categories',
      type: 'html',
    },
    brands: {
      title: 'brands',
      type: 'html',
    },
    nykaaNetwork: {
      title: '', // Title is dynamic value which will be picked from api response
      type: 'html',
      value: '',
    },
    popups: {
      title: 'nykaa fashion',
      type: 'html',
    },
    beautyAdvice: {
      title: 'beauty advice',
      type: 'html',
      value: '',
    },
    style: {
      title: '',
      type: 'html',
    },
  };

  export const NAVMAN = {
    categories: {
      title: 'categories',
      type: 'html',
    },
    brands: {
      title: 'brands',
      type: 'html',
    },
    groomingAdvice: {
      title: 'grooming advice',
      type: 'url',
      value: '/grooming-advice',
    },
    style: {
      title: '',
      type: 'html',
    },
  };
