import React from 'react';

import styled from 'App/styled';
import { RenderNavigationWithHtml } from '../Nav';


const Wrapper = styled.div`
  width: 100%;

  ul > li > a {
    text-decoration: none;
  }
`;

const NavHeaders = styled.div`
  position: relative;
  z-index: 7;
  line-height: 62px;
  display: flex;
`;

const CategoryHeaders = styled.div`
  background: #fff;
  position: relative;
  transition: all .3s ease-in-out;
  z-index: 2;

  .Addbdr {
    display: none;
  }
  .MegaDropdownHeading {
    display: flex;
  }
  .subMegaMenu_main {
    height: 50px;
  }
  transition: all .3s ease-in-out;
`;
const CategoryWrapper = styled.div`
  margin: 0 auto;
`;
const NAV = {
  categories: {
    title: 'categories',
    type: 'html',
  },
};

const Index = ({ navigationData }: any) => (
  <>
    <Wrapper className="megaMenu_main">
      <CategoryHeaders id="category_navigation">
        <CategoryWrapper className="megaMenu_main subMegaMenu_main" id="subheaderMenu">
          {navigationData && NavHeaders && (
          <RenderNavigationWithHtml
                config={NAV.categories}
                markUp={navigationData['categories']?.categories}
                id="categories"
              />
            )}
        </CategoryWrapper>
      </CategoryHeaders>
    </Wrapper>
  </>
  );

export default Index;
