import { connect } from 'react-redux';
import { State } from 'store/types';
import View from './View';


const mapStateToProps = (state: State) => {
  const { auth, appReducer } = state;
  return {
    cartCount: auth.user.cartItemsCount,
    isSliderOpen: appReducer.isSliderOpen,
  };
};

export default connect(mapStateToProps)(View);
