import React from 'react';
import styled from '@emotion/styled';
import Bag from './Bag';
import KebabMenu from './KebabMenu';
import PinkBoxEntry from './PinkBox';


const RightNav = styled.div`
  display: flex;
  align-items: center;
  margin-left: 45px;
`;
const CartIcon = styled.div``;
const Index = () => (
  <RightNav>
    <KebabMenu />
    <PinkBoxEntry />
    <CartIcon>
      <Bag />
    </CartIcon>
  </RightNav>
);

export default Index;
