import { connect } from 'react-redux';
import { State } from 'store/types';

import View from './View';
import {
  fetchTrendingSearches,
  resetSearch,
} from 'store/searchSuggestions/action';

const mapDispatchToProps = {
  fetchTrendingSearches,
  resetSearch,
};

const mapStateToProps = (state: State) => {
  const { searchSuggestions, remoteConfig, appReducer } = state;

  const {
    productSuggestions = [],
    otherSuggestions = [],
    searchAutoSuggestions = [],
    searchField = '',
  } = searchSuggestions;
  return {
    productSuggestions,
    otherSuggestions,
    searchAutoSuggestions,
    searchField,
    maxSearchHistoryCount: remoteConfig.configs.maxSearchHistoryCount,
    trendingSearchesToShow: remoteConfig.configs.trendingSearchesToShow,
    searchSuggestionIcons: remoteConfig.configs.searchSuggestionIcons,
    showScreen: searchSuggestions.showScreen,
    pageType: appReducer.pageType,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
