import React, { useState } from 'react';

import styled from 'App/styled';

const Markup = styled.div`
  div {
    display: block;
  }
`;
const Component = ({ tabs, map }: any) => {
  const [active, setActive] = useState(tabs ? tabs[0]?.key || '' : '');
  if (!(Array.isArray(tabs) || map)) {
    return <> </>;
  }
  const markup = map[active];
  return (
    <section className="brandsCategoryBox">
      <div className="BrandsCategoryHeading">
        {tabs.map((tab: any) => (
          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
          <a
            href="/"
            key={`key_brand_tabs_header_${tab.key}`}
            className={`brandHeadingbox ${
              active === tab.key ? 'current_active' : ''
            }`}
            /* eslint-disable jsx-a11y/mouse-events-have-key-events*/
            onMouseOver={() => setActive(tab.key)}
          >
            {tab.title}
          </a>
        ))}
      </div>
      <div className="BrandsnameWrapper">
        <Markup
          className="brand-content"
          dangerouslySetInnerHTML={{ __html: markup }}
        />
      </div>
    </section>
  );
};

export default Component;
