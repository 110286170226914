
export const searchTypeRaw = {
  PRODUCT : 'product',
  BRAND : 'brand',
};

export const searchTypes = {
  MISCELLANEOUS : 'Misc',
  POPULAR_PRODUCTS : 'Popular Products',
  HISTORY : 'history',
  TRENDING : 'trending',
  MANUAL : 'Manual',
  RECENT_SEARCH_LIST_ITEM : 'RECENT_SEARCH_LIST_ITEM',
  QUERY : 'query',
  BUYING_GUIDE : 'buying_guide',
};

export const SEARCH_ROOT = 'search';

export const TEMPLATE_FOR_BRAND_SEARCH = ',brand_menu,brand_list,';

export const RECENT_SEARCH_LIST = 'RECENT_SEARCH_LIST';

export const SEARCH_PARAM = 'q';

export const LAST_VISITED_SEARCH_PAGE = 'LAST_VISITED_SEARCH_PAGE';
