import { connect } from 'react-redux';
import { State } from 'store/types';
import View from './View';


const mapStateToProps = (state: State) => {
  const { searchSuggestions, remoteConfig } = state;
  return {
    trendingSearches: searchSuggestions.trendingSearches,
    trendingSearchesToShow: remoteConfig.configs.trendingSearchesToShow,
    searchSuggestionIcons: remoteConfig.configs.searchSuggestionIcons,
  };
};

export default connect(mapStateToProps)(View);
