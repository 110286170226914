import { connect } from 'react-redux';
import { State } from 'store/types';
import View from './View';
import { isEligible } from 'store/app/selector';


const mapStateToProps = (state: State) => {
  const { auth, appReducer, remoteConfig } = state;
  return {
    email: auth.user.email,
    isSmartLockActive: appReducer.configFlags.isSmartLockActive,
    name: auth.user.firstName,
    isPrive: auth.user.isLoyal,
    isAutoSignUp: isEligible(remoteConfig.configs?.AB?.autoFillSignUp, appReducer.run),
    dataFetched: auth.dataFetched,
  };
};

export default connect(mapStateToProps)(View);

