import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import AccountIcon from 'components/Icons/Account';
import LoggedInUser from 'components/Header/RightNav/KebabMenu/LoggedInUser';
import autoSignIn from 'shared/store/smartLock/autoSignin';
import { redirectAuthPage, setLoginLocation } from 'shared/helpers/auth';
import { BODY_OVERFLOW_FOR_MODAL } from 'shared/constants/dom';
import SignUp from './SignUp';
import Button from '@nykaa/ui-components/Button';
import { isAutoFillShown, setAutoFillShown } from 'shared/helpers/auth';
import { LOGIN_LOCATION } from 'constants/auth';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-right: 20px;
  padding-bottom: 8px;
  padding-top: 8px;
  align-items: center;
  .dropDown_list {
    display: none;
  }
  &:hover {
    .dropDown_list {
      display: block;
      position: absolute;
      top: 40px;
      left: 50%;
      z-index: 4;
      transform: translate(-50%, 0);
    }
  }
`;

const ButtonAccIcon = styled.div`
  background: transparent;
  border: none;
  display: flex;
  align-content: center;
`;

const ButtonWrapper = styled.div`
  min-width: 95px;

  &:hover {
    dropDown_list {
      display: none;
    }
  }
`;
const ButtonAccount = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-content: center;
  cursor: pointer;
`;

const Name = styled.span`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 76px;
  vertical-align: bottom;
  cursor: pointer;
`;

const LIST_ITEM_CLASS = 'list-item';

const DropDown = styled.div`
  .list {
    display: inline-block;
    list-style: none;
    padding: 0;
    min-width: 160px;
    background: #fff;
    border-radius: 3px;
    z-index: 12;
    box-shadow: -1px -1px 10px 0 #a8a8a8;
    width: 225px;
    .list-item {
      display: flex;
      border-bottom: 1px solid #f5f5f5;
      align-items: center;
      &:last-child {
        border-bottom: none;
      }
    }

    .link {
      display: block;
      padding: 0 20px 0 5px;
      text-decoration: none;
      height: 44px;
      line-height: 44px;
      white-space: nowrap;
      color: #001325;
      opacity: 92%;
    }
    ::after {
      content: '';
      border-style: solid;
      border-width: 6px;
      border-color: transparent;
      border-bottom-color: #fff;
      display: inline-block;
      position: absolute;
      top: -12px;
      left: 47%;
    }
  }
`;

interface KebabMenuProps {
  email?: string;
  isSmartLockActive: boolean;
  name: string;
  isAutoSignUp: boolean;
  dataFetched: boolean;
}

function KebabMenu({
  email,
  isSmartLockActive,
  name,
  isAutoSignUp,
  dataFetched,
}: KebabMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showAutofill, setAutoFill] = useState(false);
  const dropDownRef = useRef(null);
  const isLoggedIn = !!email;
  const theme: Theme = useTheme();

  const toggleMenu = () => {
    const isOpen = !isMenuOpen;
    if (isOpen) {
      document.body.classList.add(BODY_OVERFLOW_FOR_MODAL);
    } else {
      document.body.classList.remove(BODY_OVERFLOW_FOR_MODAL);
    }
    setIsMenuOpen(isOpen);
  };

  const handleClickInDropDown = (event: React.MouseEvent) => {
    const isOutsideChild = event.target === event.currentTarget;
    if (isOutsideChild) {
      event.preventDefault();
      toggleMenu();
    }
  };

  const defaultAction = () => {
    if (!isLoggedIn && isAutoSignUp) {
      setAutoFill(!showAutofill);
      return;
    }
    toggleMenu();
    setLoginLocation(LOGIN_LOCATION.MYACCOUNT_LOGIN_BTN);
    redirectAuthPage();
  };

  const handleSmartLogin = () => {
    if (isLoggedIn) {
      return;
    }
    if (isSmartLockActive) {
      setLoginLocation(LOGIN_LOCATION.MYACCOUNT_LOGIN_BTN);
      autoSignIn().catch(() => {
        defaultAction();
      });
    } else {
      defaultAction();
    }
  };

  useEffect(() => {
    if (dataFetched && isAutoSignUp && !isLoggedIn && !isAutoFillShown()) {
      setAutoFillShown();
      setAutoFill(true);
    }
  }, [isAutoSignUp, isLoggedIn, dataFetched]);

  const userName = name || 'Account';
  return (
    <Wrapper>
      {!isLoggedIn && isAutoSignUp && (
        <ButtonWrapper>
          <Button
            onClick={() => handleSmartLogin()}
            aria-label="Kebab menu"
            size="medium"
            kind="primary"
            shape="default"
          >
            Sign in
          </Button>
        </ButtonWrapper>
      )}
      {!isAutoSignUp && !isLoggedIn && (
        <ButtonAccIcon
          type="button"
          onClick={() => handleSmartLogin()}
          aria-label="Kebab menu"
        >
          <AccountIcon onClick={() => handleSmartLogin()} />
        </ButtonAccIcon>
      )}
      {isLoggedIn && (
        <ButtonAccount
          type="button"
          onClick={() => handleSmartLogin()}
          aria-label="Kebab menu"
        >
          <AccountIcon onClick={() => handleSmartLogin()} />
        </ButtonAccount>
      )}
      {showAutofill && <SignUp toggleOverlay={setAutoFill} />}
      {(isLoggedIn || !isAutoSignUp) && (
        <Name onClick={() => handleSmartLogin()}>{userName}</Name>
      )}
      {isLoggedIn && (
        <DropDown
          className="dropDown_list"
          tabIndex={0}
          ref={dropDownRef}
          textColor={theme.darkGrey}
          onClick={handleClickInDropDown}
        >
          <ul className="list">
            {isLoggedIn && <LoggedInUser listItemClass={LIST_ITEM_CLASS} />}
          </ul>
        </DropDown>
      )}
    </Wrapper>
  );
}

export default KebabMenu;
