import React, { useEffect, useRef } from 'react';
import styled from 'App/styled';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleSlider } from 'store/app/actions';
import SlidingCart from 'shared/components/SlidingCart';
import ShoppingBagForHeaderIcon from 'components/Icons/Bag';
import GamoogaNotification, { useGamoogaData } from '../Notification';
import { pushEvent } from '@nykaa/data-layer';
import { TOP_INAPP_CLICKED } from 'constants/kebabMenu';
import { CART_QUERY } from 'shared/constants';

const Button = styled.button`
  border: none;

  position: relative;
  margin-left: 10px;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;

  .cart-count {
    ${({ theme }) => theme.typography.bodySmall};
    border-radius: 50%;
    margin: 0;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -8px;
    right: -8px;
    bottom: auto;
    left: auto;
    text-align: center;
    background: ${(props) => props.theme.brandPrimary};
    color: #fff;
  }
`;

interface ShoppingBagProps {
  cartCount: number;
  isSliderOpen: boolean;
}

function ShoppingBag({ cartCount, isSliderOpen }: ShoppingBagProps) {
  const isCartSideBarOpened = useRef(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const gamoogaCartNotification = useGamoogaData();

  const { search: queryString } = history.location;
  const queryParams = new URLSearchParams(queryString);
  const hasCartQuery = queryParams.get(CART_QUERY) === '1';

  const handleTopInappEvent = () => {
    const {
      GAMOOGA_NOTIFICATION_UTM_CAMPAIGN,
      GAMOOGA_NOTIFICATION_UTM_MEDIUM,
      GAMOOGA_NOTIFICATION_UTM_SOURCE,
    } = gamoogaCartNotification.params || {};
    const inappEventData = `${GAMOOGA_NOTIFICATION_UTM_SOURCE}:${GAMOOGA_NOTIFICATION_UTM_MEDIUM}:${GAMOOGA_NOTIFICATION_UTM_CAMPAIGN}`;
    pushEvent(TOP_INAPP_CLICKED, {
      top_inapp_clicked_value_hit: inappEventData,
    });
  };

  const toggleCart = () => {
    if (gamoogaCartNotification?.str) {
      handleTopInappEvent();
    }
    dispatch(toggleSlider());
  };
  useEffect(() => {
    if (hasCartQuery && !isCartSideBarOpened.current && !isSliderOpen) {
      dispatch(toggleSlider());
      isCartSideBarOpened.current = true;
    }
  }, [dispatch, hasCartQuery, isSliderOpen]);

  if (!__SERVER__) {
    window.parent.closeCart = toggleCart;
  }
  return (
    <Button
      type="button"
      onClick={toggleCart}
    >
      <ShoppingBagForHeaderIcon />
      <GamoogaNotification gamoogaCartNotification={gamoogaCartNotification} />
      {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
      {isSliderOpen && <SlidingCart />}
    </Button>
  );
}

export default ShoppingBag;
