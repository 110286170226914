import { StoreOptions } from 'store/app/types';


export const areStoresAvailable = (storeOptions: StoreOptions): boolean => {
  const { status, stores } = storeOptions;

  if (status && (stores?.length > 0)) {
    return true;
  }

  return false;
};
