import React from 'react';


interface Props {
  height?: number;
  width?: number;
}

function Close({
  height = 24,
  width = 24,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill="#8C8D94"
        d="M12 0C5.379 0 0 5.379 0 12s5.379 12 12 12 12-5.379 12-12S18.621 0 12 0zm0 1.09A10.9 10.9 0 0122.91 12 10.9 10.9 0 0112 22.91 10.9 10.9 0 011.09 12 10.9 10.9 0 0112 1.09zM7.631 7.087a.546.546 0 00-.38.936L11.229 12 7.25 15.978a.546.546 0 10.771.771L12 12.771l3.978 3.978a.546.546 0 10.771-.771L12.771 12l3.978-3.978a.546.546 0 10-.771-.771L12 11.229 8.022 7.25a.546.546 0 00-.391-.165z"
      ></path>
    </svg>
  );
}

export default Close;
