import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { SearchSuggestion } from 'types/searchSuggestion';
import SuggestionCard from './SuggestionCard';


interface SuggestionsProps {
  suggestions: SearchSuggestion[];
  handleClick: (suggestion: SearchSuggestion, position: number) => void;
  searchField?: string;
  //textColor: string;
  searchSuggestionIcons: {};
}

interface WrapperProps {
  textColor: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ textColor }: WrapperProps) => textColor};
`;

function Suggestions({
  suggestions,
  handleClick,
  searchField = '',
  searchSuggestionIcons,
}: SuggestionsProps) {
  const theme: Theme = useTheme();
  if (!suggestions || !(suggestions.length > 0)) {
    return null;
  }

  const clickHandler = (suggestion: SearchSuggestion, position: number) => {
    // TODO: append params to URL
    handleClick(suggestion, position);
  };

  return (
    <Wrapper textColor={theme.darkGrey}>
      {suggestions.map((suggestion, index) => {
        let { q } = suggestion;
        return (
          <SuggestionCard
            key={q}
            handleClick={clickHandler}
            index={index + 1}
            searchField={searchField}
            suggestion={suggestion}
            searchSuggestionIcons={searchSuggestionIcons}
          />
        );
      })}
    </Wrapper>
  );
}

export default Suggestions;
