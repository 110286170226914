import React from 'react';
import styled from 'App/styled';
import { SearchSuggestion } from 'types/searchSuggestion';
import {
  getHighlightedSearchField,
  addEllipses,
} from 'helpers/utils/search/searchSuggestion';

const Button = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.darkGrey};
  margin: 0;
  border: none;
  padding: 10px;
  background: #fff;
  ${({ theme }) => theme.typography.bodyMedium};
  text-align: left;
  &:active {
    background: #e6e9eb;
  }
  &:hover {
    background-color: ${(props) => props.theme.brandPrimaryRgba(0.08)};
    span {
      &:before,
      &:after {
        content: none;
      }
    }
  }
  height: 37px;
  &.product {
    height: 56px;
  }
`;

const Icon = styled.span`
  margin-right: 10px;
  opacity: 0.48;
  height: 24px;
  width: 24px;
  display: flex;
  img {
    width: 24px;
  }
`;
const Text = styled.span`
  width: 100%;
  color: #001325;
  &.multiline-elpisses {
    overflow: hidden;
    position: relative;
    line-height: 20px;
    max-height: 40px;
    margin-right: -1em;
    padding-right: 1em;
    &:before {
      content: '...';
      position: absolute;
      right: 15px;
      bottom: 0;
      background: #fff;
      width: 30px;
      padding-left: 3px;
    }
    &:after {
      content: '';
      position: absolute;
      right: 31px;
      width: 15px;
      height: 20px;
      margin-top: 0.2em;
      background: #fff;
    }
  }
`;

const Title = styled.span`
  margin-right: 5px;
  ${({ theme }) => theme.typography.bodyMedium};
  width: 100%;

  b {
    ${({ theme }) => theme.typography.subTitleMedium};
  }
`;

const Subtext = styled.span`
  ${({ theme }) => theme.typography.bodySmall};
  letter-spacing: 0.5px;
  color: #89929a;
  display: inline-block;
`;

const ImageBox = styled.span`
  margin-left: auto;
  img {
    height: 35px;
    width: 35px;
    border: 1px solid #d8d8d8;
  }
`;

interface SearchSuggestionCardProps {
  suggestion: SearchSuggestion;
  searchField: string;
  index: number;
  handleClick: (suggestion: SearchSuggestion, position: number) => void;
  searchSuggestionIcons: any;
}

function SearchSuggestionCard({
  suggestion,
  searchField,
  handleClick,
  index,
  searchSuggestionIcons,
}: SearchSuggestionCardProps) {
  const { q, image, subtext, icon } = suggestion;

  const geticonUrl = (param: string) => {
    if (param in searchSuggestionIcons) {
      return searchSuggestionIcons[param];
    } else {
      return searchSuggestionIcons.query;
    }
  };

  const totalCharCount = image ? 75 : 85;
  const subTextLength = subtext && subtext.length > 0 ? subtext.length : 0;
  const actualMaxChar = totalCharCount - subTextLength;
  const actualMinChar = actualMaxChar - 3;
  let sortedQuery;
  if (subTextLength == 0) {
    sortedQuery = q;
  } else {
    sortedQuery = addEllipses(q, actualMaxChar, actualMinChar);
  }

  const { partBefore, partMatching, partAfter, match } =
    getHighlightedSearchField(searchField, sortedQuery);
  const queryText = match ? (
    <Title>
      {partBefore}
      <b>{partMatching}</b>
      {partAfter}
    </Title>
  ) : (
    q
  );
  const buttonClass = image ? 'product' : '';
  return (
    <Button
      key={q}
      className={`suggestionQuery ${buttonClass ? buttonClass : ''}`}
      data-value={q}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleClick(suggestion, index + 1);
      }}
    >
      <Icon>
        <img
          src={geticonUrl(icon)}
          alt="icon"
        />
      </Icon>

      <Text className={subTextLength == 0 ? 'multiline-elpisses' : ''}>
        {queryText}
        {subtext && <Subtext> {subtext} </Subtext>}
      </Text>

      <ImageBox>
        {image && (
          <img
            src={image}
            alt="product"
          />
        )}
      </ImageBox>
    </Button>
  );
}

export default SearchSuggestionCard;
