import React, { useCallback, useState } from 'react';
import { styled } from '@nykaa/ui-components';
// import ErrorExclamationIcon from 'components/Icons/ErrorExclamation';

import { hexToRgb } from '@nykaa/ui-components/styles/utils';


interface LabelProps {
  readOnly: boolean;
  error?: boolean;
}

const Label = styled.label<LabelProps>`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  ${({ theme }) => theme.typography.bodySmall};
  color: ${({ theme }) => hexToRgb(theme.colors.state)};
  background: ${({ theme }) => theme.colors.surface20};
  min-height: 24px;
  text-align: center;
  &.text_only {
    padding: 5px 0 0;
    ${({ theme }) => theme.typography.bodyLarge};
  }
  width: 100%;
  height: 40px;
  

`;


// Replace with new token
const Input = styled.input`
  display: block;
  height: 24px;
  border: none;
  ${({ theme }) => theme.typography.bodyLarge};
  color: rgba(0, 19, 37, 0.92);
  background: transparent;
  margin: 5px 5px 5px 10px;

  &:focus {
    border: none;
    outline: none;
  }
  &.hidden {
    height: 0;
    margin: 0 5px 0 10px;
  }
`;

interface BottomLineProps {
  readOnly?: boolean;
  error?: boolean;
  focused?: boolean;
  filled?: boolean;
}

const BottomLine = styled.div<BottomLineProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: red;
  ${(props) => {
    let height = '0';
    let left = '0';
    let right = '0';
    let background = 'rgba(101, 119, 134, 0.08)';
    let borderRadius = '0';
    if (props.error) {
      height = '2px';
      background = '#B60000';
      left = '16px';
      right = '16px';
      borderRadius = '4px 4px 0 0';
    } else if (props.focused && (!props.readOnly)) {
      height = '2px';
      background = props.theme.colors.primary;
    } else if (props.filled) {
      height = '2px';
    } else if (!props.readOnly) {
      height = '1px';
    }

  return `
      height: ${height};
      left: ${left};
      right: ${right};
      background: ${background};
      border-radius: ${borderRadius};
    `;
  }}
`;


const IconWrapper = styled.div`
position: absolute;
top: 10px;
right: 5px;
${({ theme }) => theme.typography.subTitleSmall};
color: ${({ theme }) => theme.colors.primary};

`;

interface Props extends React.HTMLProps<HTMLInputElement>{
  error?: boolean;
  label: string;
  onValChange: (e: any) => void;
  handleClick: () => void;
}


function InputWithLabel({
  error,
  value,
  onValChange,
  label,
  readOnly,
  onFocus = () => {},
  onBlur = () => {},
  handleClick,
}: Props) {
  const [focused, setFocused] = useState(false);
  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      onFocus(event);
    },
    [onFocus],
  );
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      onBlur(event);
    },
    [onBlur],
  );
  const focusVal = (focused || !!value);

  return (
    <>
      <Label
        readOnly={!!readOnly}
        error={error}
        className= {(!focusVal) ? 'text_only' : '' }
      >
        {(!focusVal) && label}
        <Input
          className={focusVal ? '' : 'hidden'}
          name="mobileNumber"
          type="tel"
          autoComplete="off"
          readOnly={readOnly}
          value={value}
          onChange={(e) => onValChange(e)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {(value?.toString().length == 10) &&
          (
            <IconWrapper onClick={ () => handleClick()}>
              Send OTP
            </IconWrapper>
          )
          }
        <BottomLine
          error={error}
          readOnly={readOnly}
          filled={!!value}
          focused={focusVal}
        />
      </Label>
    </>
  );
}

export default InputWithLabel;
