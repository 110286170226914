/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'App/styled';


const Letter = styled.a`
  && {
    color: rgba(3, 2, 26, 0.6);
    ${({ theme }) => theme.typography.bodySmall};
  }
  &: hover {
    color: ${props => props.theme.brandPrimary};
  }
`;
const List = ({ keys, onHover, active }: any) => (
  <>
    {keys.map(key => (
      <li className="Nav-item" key={`letter-${key}`} onMouseEnter={() => onHover(key)}>
        <Letter className={`Nav-link ${active === key ? 'active' : ''}`} rel="a">
          {key}
        </Letter>
      </li>
    ))}
  </>
);
export default List;
