import { userSessionTypes, platformNames } from '.';

interface Item {
  link: string;
  displayText: string;
}

interface MenuItems {
  [userSessionType: string]: Item[];
}

interface MenuItemsMap {
  [platform: string]: MenuItems;
}

const menuItems: MenuItemsMap = {
  [platformNames.NYKAA]: {
    [userSessionTypes.GUEST]: [
      {
        link: 'https://www.nykaa.com/gateway-api/omsApis/helpCenter?domain=NYKAA',
        displayText: 'Need Help?',
      },
      {
        link: '/authenticity-nykaa-guarantee?intcmp=hp,header,1,authenticity',
        displayText: 'Authenticity',
      },
      {
        link: '/nykaa-csr',
        displayText: 'Nykaa CSR',
      },
      {
        link: '/responsible-disclosure',
        displayText: 'Responsible Disclosure',
      },
    ],
    [userSessionTypes.LOGGED_IN]: [
      {
        link: '/sales/order/history/v2?ptype=myOrder',
        displayText: 'Orders',
      },
      {
        link: '/myProfile',
        displayText: 'Profile',
      },
      // {
      //   link: '/subscription',
      //   displayText: 'Nykaa Subscription',
      // },
      {
        link: '/nykaaWallet/add/?ptype=nykaaWallet',
        displayText: 'Wallet',
      },
      {
        link: '/wishlist/',
        displayText: 'Wishlist',
      },
      // {
      //   link: '/sales/order/history/v2?ptype=myOrder',
      //   displayText: 'My Product Reviews',
      // },
      // {
      //   link: '/productquestion/customer/index/?ptype=account',
      //   displayText: 'Q&A',
      // },
      // {
      //   link: 'https://www.nykaa.com/gateway-api/omsApis/helpCenter?domain=NYKAA',
      //   displayText: 'Help Center',
      // },
      // {
      //   link: '/nykaa-csr',
      //   displayText: 'Nykaa CSR',
      // },
      // {
      //   link: '/responsible-disclosure',
      //   displayText: 'Responsible Disclosure',
      // },
    ],
  },
  [platformNames.MEN]: {
    [userSessionTypes.GUEST]: [
      {
        link: 'https://www.nykaaman.com/gateway-api/omsApis/helpCenter?domain=NYKAA_MEN',
        displayText: 'Need Help?',
      },
      {
        link: '/giftcard/list',
        displayText: 'Gift Cards',
      },
      {
        link: '/responsible-disclosure',
        displayText: 'Responsible Disclosure',
      },
    ],
    [userSessionTypes.LOGGED_IN]: [
      {
        link: '/sales/order/history/v2?ptype=myOrder',
        displayText: 'My Orders',
      },
      {
        link: '/myProfile',
        displayText: 'My Profile',
      },
      {
        link: '/nykaaWallet/add/?ptype=nykaaWallet',
        displayText: 'My Wallet',
      },
      {
        link: '/wishlist/',
        displayText: 'My Wishlist',
      },
      // {
      //   link: '/sales/order/history/v2?ptype=myOrder',
      //   displayText: 'My Product Reviews',
      // },
      // {
      //   link: '/productquestion/customer/index/?ptype=account',
      //   displayText: 'Q&A',
      // },
    ],
  },
};

export const getMenuItems = (): MenuItems => menuItems[__PLATFORM__];
export const TOP_INAPP_CLICKED = 'top_inapp_clicked';
