import { localStorageService } from 'client/service/storage';
import { appendQueryStringToUrl } from 'helpers/utils/url';
import PageTypes from 'constants/pageTypes';
import { pages } from 'constants/urls';
import { logger } from '@nykaa/logger';
import { SearchSuggestion, ManualQuery } from 'types/searchSuggestion';
import {
  searchTypes,
  searchTypeRaw,
  TEMPLATE_FOR_BRAND_SEARCH,
  SEARCH_ROOT,
  RECENT_SEARCH_LIST,
} from 'constants/searchSuggestion';

const ellipses = '...';

export function handleSearchSuggestionClick(
  suggestion: SearchSuggestion,
  page: string,
  searchField?: string,
  position?: number
): void {
  const { q, type, url, listItemType } = suggestion;
  let baseUrl = url;
  const customUrlParams = new URLSearchParams();
  let searchType = searchTypes.MISCELLANEOUS;
  if (type === searchTypeRaw.PRODUCT) {
    searchType = searchTypes.POPULAR_PRODUCTS;
  } else if (type === searchTypes.TRENDING) {
    searchType = searchTypes.TRENDING;
  }

  if (listItemType === searchTypes.TRENDING) {
    searchType = searchTypes.TRENDING;
  }

  if (listItemType === searchTypes.HISTORY) {
    searchType = searchTypes.HISTORY;
  }

  let rootParam = SEARCH_ROOT;
  if (type === searchTypeRaw.BRAND) {
    rootParam = `${rootParam}${TEMPLATE_FOR_BRAND_SEARCH}${q}`;
    /**
     * * This check is essential as we have observed some brand urls
     * * we do not get "/" at the start which is critical for navigation
     */
    if (baseUrl.indexOf('/') !== 0) {
      baseUrl = `/${baseUrl}`;
    }
  }
  // TODO: Handle cases for trending searches and history;
  customUrlParams.append('root', rootParam);
  if (baseUrl.indexOf('searchType') > -1) {
    baseUrl = baseUrl.replace(/searchType.*&/, '');
    baseUrl = baseUrl.replace(/searchType.*$/, '');
  }
  customUrlParams.append('searchType', searchType);
  customUrlParams.append('suggestionType', type);
  position && customUrlParams.append('ssp', position.toString());
  searchField && customUrlParams.append('tst', searchField);
  customUrlParams.append('searchItem', q);
  customUrlParams.append('sourcepage', page || PageTypes.SEARCH_SUGGESTION);
  // search redirection query param need to be added if url is of pages other then search page

  if (!(baseUrl?.indexOf(pages.SEARCH_RESULTS) > -1)) {
    customUrlParams.append('searchRedirect', '1');
  }
  let paramString = '';
  customUrlParams.forEach((value, key) => {
    paramString += `${key}=${value}&`;
  });

  const redirectionUrl = appendQueryStringToUrl(baseUrl, paramString);

  window.location.href = redirectionUrl;
}

interface HighlightedSearchField {
  partBefore?: string;
  partMatching?: string;
  partAfter?: string;
  match: boolean;
}

export const getHighlightedSearchField = (
  searchField: string,
  q: string
): HighlightedSearchField => {
  const searchString = searchField.toLowerCase();
  const productName = q;
  const searchStringLength = searchString.length;
  const searchStringIndex = productName.toLowerCase().indexOf(searchString);

  if (searchStringIndex < 0) {
    return {
      match: false,
    };
  }
  const partBefore = productName.substr(0, searchStringIndex);
  const partMatching = productName.substr(
    searchStringIndex,
    searchStringLength
  );
  const partAfter = productName.substr(searchStringIndex + searchStringLength);

  return {
    partBefore,
    partMatching,
    partAfter,
    match: true,
  };
};

// multiline Support: Add ellpises at the last of the text
export const addEllipses = (
  queryChar: string,
  maxChar: number,
  minChar: number
) => {
  if (queryChar.length > maxChar) {
    return (queryChar = queryChar.substring(0, minChar) + ellipses);
  } else {
    return queryChar;
  }
};

export function handleSearchSuggestionForHistory(
  suggestion: SearchSuggestion | ManualQuery,
  maxItems: number = 10
) {
  const suggestionItem: SearchSuggestion | ManualQuery = {
    ...suggestion,
    listItemType: searchTypes.RECENT_SEARCH_LIST_ITEM,
  };
  try {
    const searchHistory = localStorageService.getItem(RECENT_SEARCH_LIST);
    let searchItems = [];

    if (searchHistory) {
      try {
        searchItems = JSON.parse(searchHistory);
      } catch (err) {
        logger.error(err, 'Parsing "search history"');
      }
    }

    const filteredList = searchItems.filter((searchItem: SearchSuggestion) => {
      const { q: queryFromHistory } = searchItem;
      const { q: newQuery } = suggestionItem;
      if (queryFromHistory && newQuery) {
        if (queryFromHistory.toLowerCase() === newQuery.toLocaleLowerCase()) {
          return false;
        }
      }

      return true;
    });

    searchItems = [suggestionItem, ...filteredList];
    if (searchItems.length > maxItems) {
      searchItems.pop();
    }
    localStorageService.setItem(
      RECENT_SEARCH_LIST,
      JSON.stringify(searchItems)
    );
  } catch (error) {
    logger.error(error, 'CONTEXT: handleSearchSuggestionForHistory');
  }
}

export const getSearchHistory = (): SearchSuggestion[] => {
  if (__SERVER__) {
    return [];
  }

  try {
    const searchHistory = localStorageService.getItem(RECENT_SEARCH_LIST);
    if (searchHistory) {
      const searchHistoryItems: SearchSuggestion[] = JSON.parse(searchHistory);
      return searchHistoryItems;
    }
  } catch (err) {
    logger.error(err, 'getSearchHistory');
  }

  return [];
};

export const handleNoResultKeywordsForHistory = (keyword: string): void => {
  const searchHistory = getSearchHistory();
  if (searchHistory?.length > 0) {
    const refinedHistory = searchHistory.filter(
      (item: SearchSuggestion) =>
        item.q?.toLowerCase() !== keyword?.toLowerCase()
    );
    localStorage.setItem(RECENT_SEARCH_LIST, JSON.stringify(refinedHistory));
  }
};

interface ManualSearchData {
  page: string;
  searchQuery: string;
}

export const handleManualSearch = (
  { page, searchQuery }: ManualSearchData,
  historyMaxItems: number
) => {
  const encodedSearchQuery = encodeURIComponent(searchQuery);
  let searchUrl = appendQueryStringToUrl(
    pages.SEARCH_RESULTS,
    `q=${encodedSearchQuery}`
  );

  const queryParamsForSearch = new URLSearchParams();
  queryParamsForSearch.append('root', SEARCH_ROOT);
  queryParamsForSearch.append('searchType', searchTypes.MANUAL);
  queryParamsForSearch.append(
    'sourcepage',
    page || PageTypes.SEARCH_SUGGESTION
  );

  const queryString = queryParamsForSearch.toString();
  searchUrl = appendQueryStringToUrl(searchUrl, queryString);

  /**
   * ? Why handleSearchSuggestionForHistory in here you may ask?
   * * This is because manual search item also needs the URL
   * * which is created inside this method...handleManualSearch
   */
  handleSearchSuggestionForHistory(
    {
      q: searchQuery,
      type: searchTypes.QUERY,
      url: searchUrl,
    },
    historyMaxItems
  );

  window.location.href = searchUrl;
};
