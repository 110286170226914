import { pushEvent } from '@nykaa/data-layer/utils';
import { LOGIN_PROCEED_CLICKED, LOGINTYPE, LOGIN_SIGNUP_START } from './../../../../../constants/auth';
import { setMobileNumberLocal, setAutofillFlag } from 'shared/helpers/auth';
import { pages } from 'shared/constants/urls';
import { pushData } from 'helpers/utils/dataLayer';

export const ACTIONS = {
  SEND_OTP: '1',
  SIGN_IN: '2',
  GOOGLE_SIGN: '3',
};

export const TRACKING = {
  VIEW: 'auto_fill',
  SEND_OTP: 'send_otp_autoFill',
  PAGE: 'dweb:welcomeScreen',
};

export const CURRENT_URL = 'CURRENT_URL';

export const getEncodedURl = () => encodeURIComponent(`${window.location.pathname}${window.location.search}`);

export const redirectToAuthVerify = () => {
  const url = `${pages.AUTH_PAGE_VERIFY}&redirect=${getEncodedURl()}`;
  setTimeout(() => {
    window.location.href = url;
  }, 500);
};

export const loginPageName = () => {
  let loginPage = 'homePage';
  let cURL = null;
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      cURL = window.localStorage.getItem(CURRENT_URL);
      if (cURL?.includes('/p/')) {
        loginPage = 'productDetailPage';
      } else if (cURL?.includes('/search/') || cURL?.includes('/c/')) {
        loginPage = 'productListingPage';
      } else if (cURL?.includes('/reviews/')) {
        loginPage = 'productReviewPage';
      } else if (cURL?.includes('/widgetName/')) {
        loginPage = 'recoWidget';
      } else if (cURL?.includes('/lp')) {
        loginPage = 'CMSPage';
      }
    }
    return loginPage || '';
  } catch (error) {
    return '';
  }
};

export const redirectToAuth = () => {
  const url = `${pages.AUTH_PAGE}&redirect=${getEncodedURl()}`;
  setTimeout(() => {
    window.location.href = url;
  }, 500);
};

export const handleAutoFillSubmit = (mobile: string) => {
  pushData({ event: TRACKING.SEND_OTP });
  pushEvent(LOGIN_PROCEED_CLICKED, { loginType: LOGINTYPE.PHONE, login_page: loginPageName() });
  setMobileNumberLocal(mobile);
  setAutofillFlag(ACTIONS.SEND_OTP);
  redirectToAuthVerify();
};

export const handleLogin = () => {
  setAutofillFlag(ACTIONS.SIGN_IN);
  pushEvent(LOGIN_SIGNUP_START, { loginType: LOGINTYPE.PHONE_EMAIL, login_page: loginPageName() });
  redirectToAuthVerify();
};
export const handleGLogin = () => {
  setAutofillFlag(ACTIONS.GOOGLE_SIGN);
  pushEvent(LOGIN_SIGNUP_START, { loginType: LOGINTYPE.GOOGLE, login_page: loginPageName() });
  redirectToAuth();
};

export const trackView = (number?: string) => {
  const autoFill = { pageName: TRACKING.PAGE, event: TRACKING.VIEW, phfill: number ? 1 : 0 };
  pushData({ autoFill });
};
