import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@nykaa/ui-components';

import Button from '@nykaa/ui-components/Button';
import { hexToRgb } from '@nykaa/ui-components/styles/utils';
import InputWithLabel from './InputWithLabel';
import { getMobileNumberLocal } from 'shared/helpers/auth';
import PathIcon from 'components/Icons/Path';

import GoogleIcon from 'components/Icons/GoogleG';
import {
  handleAutoFillSubmit,
  handleGLogin,
  handleLogin,
  trackView,
} from './helper';
import { useSelector } from 'react-redux';
import { State } from 'store/types';
import { checkIsIndianUser } from 'helpers/utils/user';
import { getEligibleForVariant } from 'helpers/experimentV2';
import { AB_AUTH_DWEB } from 'constants/auth';

import DropdownNudge from '@nykaa/auth-desktop/components/DropdownNudge';

const SHOW_SIGN_UP_THRESHOLD = 500;
const Wrapper = styled.div`
  position: absolute;
  top: 55px;

  z-index: 6;
  transform: translate(-50%, 0);
  background: #ffffff;
  padding: 20px;
  width: 320px;
  border-radius: 8px;
  box-shadow: 0px 8px 32px
    ${({ theme }) => hexToRgb(theme.colors.textPrimary, 0.48)};
  ::before {
    bottom: 100%;
    right: 30%;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 10px solid hsla(0, 0%, 100%, 0);
    border-bottom-color: #fff;
    border-radius: 2px;
  }
`;
const InputContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 4px;
  button {
    margin-top: 16px;
    text-align: left;
    display: flex;
    height: 40px;

    align-items: center;

    svg {
      margin-right: 5px;
    }
  }
`;

const Title = styled.div`
  margin: 8px 0;
  ${({ theme }) => theme.typography.type241}
  color: #000F1D;
`;
// const SubTitle = styled.div`
//   margin-top: 8px;
//   ${({ theme }) => theme.typography.type140}
//     color: ${({ theme }) => hexToRgb(theme.colors.textPrimary, 0.64)};
// `;
const MessageContainer = styled.div`
  margin: 8px 0;

  ${({ theme }) => theme.typography.bodyMedium}
  color: ${({ theme }) => hexToRgb(theme.colors.textPrimary, 0.64)};
`;

const SeparatorTitle = styled.span`
  ${({ theme }) => theme.typography.bodySmall}
  color: ${({ theme }) => hexToRgb(theme.colors.textPrimary, 0.64)};
  margin: 0 8px;
`;
const Line = styled.div`
  height: 1px;
  background: ${({ theme }) => hexToRgb(theme.colors.textPrimary, 0.16)};
  flex: 1;
  margin-top: 8px;
`;

const Separator = styled.div`
  display: flex;
  margin: 16px 0 2px;
`;

const MobileText = styled.span`
  width: 100%;
  color: ${({ theme }) => hexToRgb(theme.colors.textPrimary, 0.64)};
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;
interface Props {
  toggleOverlay: (display: boolean) => void;
}

const Component = ({ toggleOverlay }: Props) => {
  const region = useSelector((state: State) => state.appReducer.region);
  const isNewAuthEnabled = useSelector((state: State) =>
    getEligibleForVariant(state, AB_AUTH_DWEB, 'A')
  );
  const isIndianUser = checkIsIndianUser(region.regionHeaderCountry);
  const mobileNumber = getMobileNumberLocal();
  const ref = useRef(null);
  const [mobile, setMobile] = useState('');
  useEffect(() => {
    if (mobileNumber) {
      setMobile(mobileNumber);
    }
  }, [mobileNumber]);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const validatedValue = value?.replace(/\D/g, '').slice(0, 10) || '';
    setMobile(validatedValue);
  };

  useEffect(() => {
    const checkScroll = () => {
      if (window.pageYOffset > SHOW_SIGN_UP_THRESHOLD) {
        toggleOverlay(false);
      }
    };
    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  });

  useEffect(() => {
    trackView(mobileNumber || '');
  }, [mobileNumber]);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current?.contains(event.target)) {
      toggleOverlay(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const renderNudge = () => {
    if (isNewAuthEnabled) {
      return (
        <DropdownNudge
          mobile={mobile}
          setValue={(value: string) => setMobile(value)}
          isIndianUser={isIndianUser}
        />
      );
    }
    return (
      <>
        <Title>Login / Create Account</Title>
        <MessageContainer>
          Register now and get <b>1000 Nykaa reward points instantly!</b>
        </MessageContainer>
        {mobileNumber && <MobileText>Mobile number</MobileText>}
        <InputContainer>
          {mobileNumber && isIndianUser && (
            <>
              <InputWithLabel
                value={mobile}
                onValChange={onChange}
                autoComplete="off"
                type="tel"
                name="mobileNumberNew"
                readOnly={false}
                label="10-digit mobile number"
                error={false}
                handleClick={() => handleAutoFillSubmit(mobile)}
              />
              <Separator>
                <Line />

                <SeparatorTitle>Or sign in using</SeparatorTitle>
                <Line />
              </Separator>
            </>
          )}
          {isIndianUser ? (
            <>
              <Button
                onClick={handleLogin}
                size="medium"
                kind="secondary"
                shape="default"
              >
                Sign in with Mobile / Email
                <IconWrapper>
                  <PathIcon />
                </IconWrapper>
              </Button>
              <Button
                onClick={handleGLogin}
                size="medium"
                kind="secondary"
                shape="default"
              >
                <GoogleIcon />
                Google
                <IconWrapper>
                  <PathIcon />
                </IconWrapper>
              </Button>
            </>
          ) : (
            <Button
              onClick={handleLogin}
              size="medium"
              kind="secondary"
              shape="default"
            >
              Sign in with Mobile Number
              <IconWrapper>
                <PathIcon />
              </IconWrapper>
            </Button>
          )}
        </InputContainer>
      </>
    );
  };

  return (
    <Wrapper
      ref={ref}
      tabIndex={0}
    >
      {renderNudge()}
    </Wrapper>
  );
};

export default Component;
