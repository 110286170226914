import React from 'react';
import styled from 'App/styled';
import { useTheme } from 'emotion-theming';
import { TrendingSearch } from 'types/searchSuggestion';


interface ButtonProps {
  textColor: string;
}

const TopSearches = styled.div`
  background-color: #ffffff;
  margin-top: 10px;
`;

const Title = styled.div`
  font-weight: bold;
  color: #fff;
  background: #636363;
  padding: 5px 10px;
  margin-bottom: 10px;
  line-height: normal;
  margin-top: 0;
  height: 29px;
`;

const Item = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ textColor }: ButtonProps) => textColor};
  margin: 0;
  border: none;
  padding: 12px;
  background: #fff;
  text-align: left;
  &:active {
    background: #e6e9eb;
  }
  height: 37px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.brandPrimaryRgba(0.08)};
  }

`;

const Icon = styled.span`
  height: 24px;
  width: 24px;
  margin-right: 16px;
  opacity: 0.48;
  display: flex;
  flex-direction: row;
`;

const Text = styled.span`
  width: 100%;
  color: #001325;
  margin-right: 48px;
  ${({ theme }) => theme.typography.bodyMedium};
  letter-spacing: 0.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface TrendingSearchProps {
  handleClick: (searchItem: TrendingSearch, position: number) => void;
  trendingSearches: TrendingSearch[];
  trendingSearchesToShow: number;
  searchSuggestionIcons: { trending: string };
}

function View({
  handleClick,
  trendingSearches,
  trendingSearchesToShow,
  searchSuggestionIcons,
}: TrendingSearchProps) {
  const theme: Theme = useTheme();
  if (trendingSearchesToShow < 1 || (trendingSearches && !(trendingSearches.length > 0))) {
    return null;
  }

  const clickHandler = (searchItem: TrendingSearch, position: number) => {
    handleClick(searchItem, position);
  };

  const { trending } = searchSuggestionIcons;

  const itemsToShow = trendingSearches.slice(0, trendingSearchesToShow);

  return (
    <TopSearches>
      <Title>Trending Searches</Title>
      <ul>
        {itemsToShow.map((search, index) => {
        const { q } = search;
        return (
          <Item
            className="suggestionQuery"
            data-value={q}
            textColor={theme.darkGrey}
            key={q}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              clickHandler(search, index + 1);
            }}
          >
            <Icon>
              <img src={trending} alt="trending icon" />
            </Icon>
            <Text>{q}</Text>
          </Item>
        );
      })}
      </ul>

    </TopSearches>
  );
}

export default View;
