import React from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: React.ReactNode;
}

interface PortalState {
  isMounted: boolean;
}

let portalNode: HTMLElement;
const getPortalNode = (): HTMLElement => {
  if (portalNode !== undefined) {
    return portalNode;
  }

  const node = document.getElementById('portal-root');
  if (node === null) {
    throw new Error('Portal Element not Found');
  }
  portalNode = node;
  return portalNode;
};

class Portal extends React.PureComponent<PortalProps, PortalState> {
  public state = {
    isMounted: false,
  };

  // @ts-ignore - We are initializing these on ComponentDidMount to avoid SSR issues
  private portalNode: HTMLElement;
  // @ts-ignore - We are initializing these on ComponentDidMount to avoid SSR issues
  private el: HTMLElement;

  public render() {
    if (this.state.isMounted === false) {
      return null;
    }

    return createPortal(this.props.children, this.el);
  }

  public componentDidMount() {
    this.el = document.createElement('div');
    this.portalNode = getPortalNode();
    this.portalNode.appendChild(this.el);
    this.setState({ isMounted: true });
  }

  public componentWillUnmount() {
    this.portalNode.removeChild(this.el);
  }
}

export default Portal;
