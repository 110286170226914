import React, { useEffect } from 'react';
import styled from 'App/styled';
import Search from 'layouts/SearchAutoSuggestions';
import Logo from './Logo';
import { Navigation } from '../Nav';
import { NAV, NAVMAN } from 'constants/header';
import { platformNames } from 'shared/constants';


const Wrapper = styled.div`
  width: 85%;
  ul > li > ul {
    display: none;
    width: 1140px;
    background: #ffffff;
    position: absolute;
    z-index: 7;
    left: 0;
    padding-top: 20px;
    margin-top: -12px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  ul:hover li ul {
    display: block;
  }
`;

const NavHeaders = styled.div`
  position: relative;
  z-index: 5;
  line-height: 62px;
  display: flex;
`;

const SearchContainer = styled.div`
  width: 25%;
  height: 40px;
  display: inline-block;
`;
const NavContainer = styled.div`
  width: 65%;
  display: flex;
  flex-wrap: wrap;
`;

const Index = ({ navigationData }: any) => {
  useEffect(() => {
    setTimeout(() => {
      const elems = document.getElementsByClassName('MegaDropdownHeadingbox');
      if (!elems || elems.length == 0) {
        return;
      }
      Array.from(elems).forEach(function(element) {
        element.addEventListener('mouseover', e => {
          if (!e.target || !e.target.getAttribute('data-link-name')) {
            return;
          }
          const otherElems = document.getElementsByClassName('MegaDropdownHeadingbox');
          Array.from(otherElems).forEach(function(el) {
            const attrName = el.getAttribute('data-link-name');
            // document.getElementById('categoryHeaders').style.display = 'none';
            const hideElement = document.querySelectorAll(attrName);
            if (hideElement && hideElement[0] && hideElement[0].style) {
              hideElement[0].style.display = 'none';
            }
            el.classList.remove('current_active');
          });
          e.target.classList.add('current_active');
          const attrName = e.target.getAttribute('data-link-name');
          const showElement = document.querySelectorAll(attrName);
          if (showElement && showElement[0] && showElement[0].style) {
            showElement[0].style.display = 'block';
          }
        });
      });
    }, 2000);
  }, []);
  const nav = (__PLATFORM__ == platformNames.MEN) ? NAVMAN : NAV;
  return (
    <>
      <Wrapper className="megaMenu_main">
        <NavHeaders id="headerMenu">
          <Logo />
          <NavContainer>
            <Navigation config={nav} data={navigationData} />
          </NavContainer>
          <SearchContainer>
            <Search />
          </SearchContainer>
        </NavHeaders>
      </Wrapper>
    </>
  );
};

export default Index;
