import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

import styled from 'App/styled';
import CloseIcon from 'shared/components/Icons/Close';
import Content from './content';
import LetterList from './LetterList';
import { getBrandsLetters, filterBrands } from './helpers';

const Wrapper = styled.ul`
  padding-top: 0 !important;
`;

const ClearButton = styled.button`
  background: #fff;
  border: none;
  position: absolute;
  right: 5px;
  height: 20px;
  width: 20px;
  top: 5px;
`;

const Section = styled.section`
  width: 360px;
  float: left;
  background: #fff;
  height: 510px;
  border-bottom-left-radius: 3px;
`;

const ListWrapper = styled.div`
  width: 295px;
  // float: left;
  // height: 430px;
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.type142};
  color: rgba(3, 2, 26, 0.6);
  text-transform: uppercase;
  margin-bottom: 0;
  height: 68px;
  position: relative;
  border-bottom: none;
`;
const Item = styled.div`
  line-height: 25px;
  position: relative;
  a {
    color: rgba(3, 2, 26, 0.6);
    &: hover {
      color: ${(props) => props.theme.brandPrimary};
    }
  }
`;

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing.spacing50};
  padding-left: 18px;
  display: flex;
  position: relative;
  height: calc(85% - 10px);
  .brandsSorting {
    position: relative;
    width: 50px;
    text-align: center;
  }
  .ss-content {
    right: -20px;
  }
`;

const brandsTabs = [
  {
    title: 'Popular',
    key: 'brands_Popular',
  },
  {
    title: 'Luxe',
    key: 'brands_Featured',
  },
  {
    title: 'Only At Nykaa',
    key: 'brands_Exclusive',
  },
  {
    title: 'New Launches',
    key: 'brands_Launches',
  },
];

const handleKeyHover = (key, CB) => {
  const activeKey = key === '*' ? 'topbrands' : key;
  const elem = document.getElementById(`list_${activeKey}`);
  if (elem) {
    elem.scrollIntoView();
    CB(activeKey);
  }
};

const getBrandUrl = (brand: any, key: string) => {
  const { request_path: requestPath, name } = brand || {};
  if (!key || !requestPath) {
    return '';
  }
  let separator = '&';
  if (requestPath.indexOf('?') <= -1) {
    separator = '?';
  }
  const brandParam = `${separator}root=brand_menu,${
    key === 'topbrands' ? 'top_brands' : 'brand_list'
  }`;
  return `/${requestPath}${brandParam},${name}`;
};

const Component = ({ data = {} }: any) => {
  const [activeKey, setActiveKey] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [filteredBrands, setFilteredBrands] = useState({});
  const isMounted = useRef(false);
  /* eslint-disable react-hooks/exhaustive-deps */
  const handleBrandSearch = useCallback(
    debounce(() => {
      setFilteredBrands(filterBrands(data, inputRef?.current?.value || ''));
    }, 200),
    [inputRef.current, data]
  );

  const handleCrossIcon = () => {
    if (inputRef?.current) {
      inputRef.current.value = '';
      handleBrandSearch();
    }
  };

  useEffect(() => {
    if (Object.keys(data).length && isMounted.current) {
      setFilteredBrands(filterBrands(data, ''));
    }
    isMounted.current = true;
  }, [data, isMounted]);

  if (!data || typeof data !== 'object' || Array.isArray(data)) {
    return <></>;
  }
  return (
    <Wrapper className="hideArr normal-sub">
      <li>
        <Section className="allBrandsBox clearfix">
          <div className="brandSearchWrapper">
            <div className="brandSearchMain">
              <div className="SearchIcon"></div>
              <input
                ref={inputRef}
                type="text"
                id="brandSearchBox"
                placeholder="search brands"
                onChange={handleBrandSearch}
              />
              {inputRef?.current?.value && (
                <ClearButton
                  className="clear-search"
                  onClick={handleCrossIcon}
                >
                  <CloseIcon />
                </ClearButton>
              )}
            </div>
          </div>
          <Container>
            <ListWrapper className="brandsNameboxWrapper ss-container">
              <div className="ss-wrapper">
                <div
                  className="ss-content desktop-header"
                  id="scroller-container"
                >
                  {filteredBrands &&
                    Object.keys(filteredBrands).map((key) => (
                      <>
                        <Title
                          id={`list_${key}`}
                          key={`list_${key}`}
                        >
                          {key}
                        </Title>

                        {Array.isArray(filteredBrands[key]) &&
                          filteredBrands[key].map((item) => (
                            <Item key={`fb-${item.name}`}>
                              <a href={getBrandUrl(item, key)}>{item.name}</a>
                            </Item>
                          ))}
                      </>
                    ))}
                </div>
              </div>
            </ListWrapper>

            <div className="brandsSorting">
              <LetterList
                keys={getBrandsLetters(data)}
                onHover={(activKey) => handleKeyHover(activKey, setActiveKey)}
                active={activeKey}
              />
            </div>
          </Container>
        </Section>
        <Content
          tabs={brandsTabs}
          map={data}
        />
      </li>
    </Wrapper>
  );
};

export default Component;
