import { pages } from 'constants/urls';
import { trackingParams } from 'constants/queryParams';
import { pushEvent } from '@nykaa/data-layer';
import { TOP_INAPP_CLICKED } from 'constants/kebabMenu';

export interface GamoogaParams {
  GAMOOGA_NOTIFICATION_UTM_SOURCE: string;
  GAMOOGA_NOTIFICATION_UTM_MEDIUM: string;
  GAMOOGA_NOTIFICATION_UTM_CAMPAIGN: string;
}
export interface GamoogaNotificationProps {
  str: string;
  params: GamoogaParams;
}

export const addWindowHandler = (callBack: (arg0: any) => void) => {
  window.handleGamoogaCartNotification = (str: string, obj: any) => {
    if (typeof str !== 'string' && typeof obj !== 'object') {
      return;
    }
    const {
      GAMOOGA_NOTIFICATION_NOTIFICATION_DISPLAY_TIME_IN_SEC,
      GAMOOGA_NOTIFICATION_NOTIFICATION_ADDITIONAL_DISPLAY_TIME_IN_SEC,
      ...params
    } = obj;
    if (typeof callBack === 'function') {
      callBack({ str, params });
    }
    const milliSeconds =
      (GAMOOGA_NOTIFICATION_NOTIFICATION_DISPLAY_TIME_IN_SEC +
        GAMOOGA_NOTIFICATION_NOTIFICATION_ADDITIONAL_DISPLAY_TIME_IN_SEC) *
      1000;
    setTimeout(() => {
      if (typeof callBack === 'function') {
        callBack(null);
      }
    }, milliSeconds);
  };
};

export const handleCartNotificationOnclick = (
  gamoogaCartNotification: GamoogaNotificationProps | null
) => {
  const { params } = gamoogaCartNotification || {};
  const {
    GAMOOGA_NOTIFICATION_UTM_SOURCE,
    GAMOOGA_NOTIFICATION_UTM_MEDIUM,
    GAMOOGA_NOTIFICATION_UTM_CAMPAIGN,
  } = params || {};
  let url = pages.CHECKOUT_PAGE;
  const trackParams = [];
  if (GAMOOGA_NOTIFICATION_UTM_SOURCE) {
    trackParams.push(
      `${trackingParams.UTM_SOURCE}=${GAMOOGA_NOTIFICATION_UTM_SOURCE}`
    );
  }
  if (GAMOOGA_NOTIFICATION_UTM_MEDIUM) {
    trackParams.push(
      `${trackingParams.UTM_MEDIUM}=${GAMOOGA_NOTIFICATION_UTM_MEDIUM}`
    );
  }
  if (GAMOOGA_NOTIFICATION_UTM_CAMPAIGN) {
    trackParams.push(
      `${trackingParams.UTM_CAMPAIGN}=${GAMOOGA_NOTIFICATION_UTM_CAMPAIGN}`
    );
  }
  if (trackParams.length > 0) {
    url += `?${trackParams.join('&')}`;
    const inappEventData = `${GAMOOGA_NOTIFICATION_UTM_SOURCE}:${GAMOOGA_NOTIFICATION_UTM_MEDIUM}:${GAMOOGA_NOTIFICATION_UTM_CAMPAIGN}`;
    pushEvent(TOP_INAPP_CLICKED, {
      top_inapp_clicked_value_hit: inappEventData,
    });
  }
  window.location.href = url;
};
