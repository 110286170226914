import React, { memo, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import RightNav from './RightNav';
import Navigation from './LeftNav';
import BottomNav from './BottomNav';
import TopStrip from './TopStrip';


const Wrapper = styled.header`
  position: relative;
  height: 162px;
`;

const Container = styled.div`
  background: #fff;
  position: fixed;
  z-index: 10;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  left: 0;
  right: 0;
`;

const Top = styled.div`
  padding-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 83.25em;
  margin: 0 auto;
`;
const MiddleNav = styled.div`
  border-bottom: solid 1px #e6dede;
  width: 100%;
`;

const Header = (props: any) => {
  const { navigationData, fetchNavigation } = props;
 const fetch = useCallback(() => {
   fetchNavigation();
 }, [fetchNavigation]);
  useEffect(() => {
    fetch();
  }, [fetch]);
  //fetchNavigation();
  return (
    <Wrapper>
      <Container id="header_id">
        <TopStrip markup={navigationData?.topStrip} />
        <MiddleNav>
          <Top>
            <Navigation {...props} />
            <RightNav />
          </Top>
        </MiddleNav>
        <BottomNav {...props} />
      </Container>
    </Wrapper>
  );
};

Header.displayName = 'Header';

export default memo(Header);
