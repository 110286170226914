import { NAV_BRAND_QUERY_SANITIZATION } from 'shared/constants';


export const getBrandsLetters = (brandsData = {}) => {
  const keysArray = Object.keys(brandsData).filter(key => key.length === 1 && key !== '#');
  return ['*', ...keysArray, '#'];
};


export const filterBrands = (brands: Record<string, any>, searchTerm = '') => {
  if (typeof brands !== 'object' || Array.isArray(brands)) {
    return {};
  };
  if (!searchTerm || (typeof searchTerm !== 'string')) {
    return brands;
  }
  const sanitizedSearchTerm = searchTerm.replace(NAV_BRAND_QUERY_SANITIZATION, '');
  const lowerCaseTerm = sanitizedSearchTerm.toLowerCase();
  const filteredBrands = { };
  Object.keys(brands).map((key) => {
    const brandKeyArray = brands[key];
    if (!Array.isArray(brandKeyArray) ) {
      return;
    }
    const filteredList = brandKeyArray?.filter(brand => {
      const sanitizedBrandName = brand.name.replace(NAV_BRAND_QUERY_SANITIZATION, '').toLowerCase();
      return sanitizedBrandName.indexOf(lowerCaseTerm) !== -1;
    });
    if ( filteredList?.length > 0) {
      filteredBrands[key] = filteredList;
    }
  });
  return filteredBrands;
};
