import React from 'react';
import styled from 'App/styled';
import AnimatedLoader from 'components/BelowTheFoldControl/AnimatedLoader';


const Wrapper = styled.div`
  margin-top: 10px;
  background: #fff;
`;

const LoadingItem = styled(AnimatedLoader)`
  height: 55px;
  margin-bottom: 5px;
`;


interface LoadingProps {
  items?: number;
}

function Loading({
  items = 4,
}: LoadingProps) {
  const iterator = new Array(items).fill(0);

  return (
    <Wrapper>
      {
        iterator.map((item, index) => {
          const key = `${item}-${index}`;
          return (
            <LoadingItem className="client" key={key} />
          );
        })
      }
    </Wrapper>
  );
}

export default Loading;
