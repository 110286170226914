import { connect } from 'react-redux';
import { State } from 'store/types';

import { pinkBoxDataSelector } from '@nykaa/prices-revealed-shared/utils';
import View from './View';


const mapStateToProps = (state: State) => {
  const { remoteConfig, auth } = state;
  const { active = false, pinkBoxUrl } = pinkBoxDataSelector(remoteConfig?.configs?.priceReveal);
  const { user: { email } } = auth;
  return {
    active,
    pinkBoxUrl,
    email,
  };
};

export default connect(mapStateToProps)(View);
