import React from 'react';
import { scaleImage } from '@nykaa/utils/image';
import styled from 'App/styled';
import { StoreOptions } from 'store/app/types';
import { areStoresAvailable } from 'helpers/utils/store';
import { platformNames } from 'shared/constants';
import NykaaLogoIcon from 'components/Icons/NykaaLogo';
import NykaaManLogoIcon from 'components/Icons/NykaaManLogo';

const Wrapper = styled.a<{ isMan: boolean }>`
  // background: url(https://adn-static1.nykaa.com/media/wysiwyg/HeaderIcons/NykaaLogoSvg.svg) no-repeat;
  display: inline-block;
  vertical-align: middle;
  height: 50px;
  ${({ isMan }) => !isMan && 'line-height: 50px;'};
  width: ${({ isMan }) => (isMan ? '14.5%' : '9.5%')};
  background-size: cover;
  svg rect {
    fill: ${({ color }) => color};
  }

  svg {
    vertical-align: middle;
  }
`;
interface Props {
  storeId: string;
  storeOptions: StoreOptions;
}

function View({ storeId, storeOptions }: Props) {
  const { stores } = storeOptions;

  if (areStoresAvailable(storeOptions)) {
    const [selectedStore] = stores.filter((store) => store.storeId === storeId);

    if (selectedStore) {
      const { storeLogo } = selectedStore;
      const scaledLogo = scaleImage({
        url: storeLogo,
        height: 24,
      });

      return (
        <Wrapper
          href="/?root=logo"
          className="logo"
          title="logo"
        >
          <img
            src={scaledLogo}
            className="logo-img"
            alt="brand-logo"
          />
        </Wrapper>
      );
    }
  }

  return (
    <Wrapper
      href="/?root=logo"
      className="logo"
      title="logo"
      isMan={__PLATFORM__ == platformNames.MEN}
    >
      {__PLATFORM__ == platformNames.MEN ? (
        <NykaaManLogoIcon />
      ) : (
        <NykaaLogoIcon />
      )}
    </Wrapper>
  );
}

export default View;
