import React, { useRef, useEffect } from 'react';
import styled from 'App/styled';
import { useSelector } from 'react-redux';
import debounce from 'lodash-es/debounce';
import { SEARCH_INPUT_PLACEHOLDER } from 'constants/placeHolders';
import SearchIcon from 'components/Icons/Search';
import { handleManualSearch } from 'helpers/utils/search/searchSuggestion';
import { logger } from '@nykaa/logger';
import { useLocation } from 'react-router-dom';
import { SEARCH_PARAM } from 'constants/searchSuggestion';
import { State } from 'store/types';
import Cross from 'components/Icons/Cross';


const elemName = 'search-suggestions-nykaa';
const Form = styled.form`
  padding: 0 18px 0 35px;
  align-items: center;
  width: 100%;
`;

const StyledInput = styled.input`
  border: none;
  background: #f4f4f4;
  width: 100%;
  ${({ theme }) => theme.typography.subTitleMedium};
  &:focus {
    outline: none;
  }
`;

const IconWrapper = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  border: none;
  background: transparent;
  top: 7px;
  align-items: center;

  svg {
    opacity: 48%;
  }
`;
const Icon = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  border: none;
  background: transparent;
  top: 7px;
  align-items: center;
  right: 5px;
  cursor: pointer;
  height: 16px;
  width: 16px;
  svg {
    width: 100%;
    path {
      fill: #5c6874;
    }
  }
`;

const Wrapper = styled.div`
  border: 1px solid rgba(151, 151, 151, 0.1);
  width: 100%;
  padding: 0 2%;
  display: flex;
  align-items: center;
  height: 40px;

  background: rgba(151, 151, 151, 0.1);
  transition: width 0.5s ease-in-out;
  position: relative;
  z-index: 10;
  border-radius: 4px;
  ${({ theme }) => theme.typography.subTitleMedium};
  &.active {
    width: 375px;
    border-color: ${props => props.theme.brandPrimary};
  } 
`;

interface SearchBarProps {
  pageType: string;
  maxSearchHistoryCount: number;
  fetchSearchSuggestion: (val: any, isPro: boolean) => Promise<void>;
  updateSearchField: (val: any) => Promise<void>;
  isPro: boolean;
  initializeScreen: (flag: boolean) => Promise<void>;
  customClass: string;
}

const SearchBar = ({
  pageType,
  maxSearchHistoryCount,
  fetchSearchSuggestion,
  updateSearchField,
  isPro,
  initializeScreen,
  customClass,
}: SearchBarProps) => {
  const inputRef = useRef(null);
  const initialRef = useRef(false);
  const searchTerm = useSelector(({ searchListingPage }: State) => searchListingPage.searchTerm);
  const searchField = useSelector(({ searchSuggestions }: State) => searchSuggestions.searchField);
  const search = useLocation().search;
  const urlParams = new URLSearchParams(search);
  const searchParam = urlParams.has(SEARCH_PARAM);
  const timeoutId = setTimeout(() => '', 0);
  const blurTimeout = useRef(timeoutId);
  const onChange = debounce(val => {
    updateSearchField(val);
    fetchSearchSuggestion(val, isPro);
  }, 100);

  useEffect(() => {
    if (inputRef?.current) {
      // inputRef?.current?.focus();
      const searchText = searchTerm || '';
      if (searchText && searchParam && (!initialRef?.current)) {
        try {
          inputRef.current.value = searchText;
          initialRef.current = true;
          onChange(searchText);
        } catch (err) {
          logger.error(err, 'Parsing "search history"');
        }
      }
    }
  }, [inputRef, onChange, searchParam, searchTerm]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const suggestionElement = e.target.elements[elemName];
    if (suggestionElement) {
      const searchValue = suggestionElement.value;
      if (searchValue) {
        handleManualSearch({ page: pageType, searchQuery: searchValue }, maxSearchHistoryCount);
      }
    }
  };

  const showScreen = flag => {
    initializeScreen(flag);
  };
  const handleChange = e => {
    onChange(e.target.value);
  };
  const handleScreen = () => {
    showScreen(true);
  };
  const handleCross = () => {
    if (blurTimeout?.current) {
      clearTimeout(blurTimeout.current);
    }
    if (inputRef?.current) {
    inputRef.current?.focus();
    inputRef.current.value = '';
    }
    updateSearchField('');
    handleScreen();
  };
  const onBlur = () => {
    const isFocused = (inputRef?.current != document.activeElement);
    if (isFocused) {
      blurTimeout.current = setTimeout(() => {
        showScreen(false);
       }, 300);
    }
  };
  useEffect(() => {
    if (searchField !== inputRef?.current?.value) {
      inputRef.current.value = searchField;
    }
  }, [searchField]);
  return (
    <Wrapper className={customClass}>
      <IconWrapper type="submit">
        <SearchIcon />
      </IconWrapper>
      <Form onSubmit={handleSubmit}>
        <StyledInput
          placeholder={SEARCH_INPUT_PLACEHOLDER}
          name={elemName}
          onChange={handleChange}
          autoComplete="off"
          ref={inputRef}
          onMouseDownCapture={handleScreen}
          onBlur={onBlur}
        />
      </Form>
      {searchField && <Icon type="submit" onClick={handleCross}>
        <Cross/>
      </Icon>}
    </Wrapper>
  );
};

export default SearchBar;
