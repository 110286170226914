import React, { useCallback, useEffect, useRef, useState } from 'react';
import PageTypes from 'constants/pageTypes';
import styled from 'App/styled';

import {
  handleSearchSuggestionClick,
  handleSearchSuggestionForHistory,
} from 'helpers/utils/search/searchSuggestion';
import { SearchSuggestion, TrendingSearch } from 'types/searchSuggestion';
import { searchTypes } from 'constants/searchSuggestion';
import Header from './Header';
import Suggestions from './Suggestions';
import Loading from './Loading';
import Trending from './Trending';
import History from './History';
import { useDispatch } from 'react-redux';
import {
  resetSuggestions,
  updateSearchField,
} from 'store/searchSuggestions/action';
import { sendSingleEvent } from '@nykaa/analytics-utils/helpers/analytics';
import {
  getASLoad as getSuggestionsLoadEvent,
  getASClick as getSuggestionsClickEvent,
  searchInputEvent as getSearchInputBoxClickEvent,
} from '@nykaa/analytics-utils/helpers/autoSuggestion';

interface SearchSuggestionProps {
  pageType: PageTypes;
  searchField: string;
  maxSearchHistoryCount: number;
  trendingSearchesToShow: number;
  searchSuggestionIcons: [];
  fetchTrendingSearches: () => Promise;
  resetSearch: () => void;
  showScreen: boolean;
  otherSuggestions: SearchSuggestion[];
  productSuggestions: SearchSuggestion[];
}
const Box = styled.div`
  background: #ffffff;
  flex-direction: column;
  color: #3f414d;
  max-height: 547px;
  width: 375px;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-top: 0;
  box-shadow: 0px 0px 67px -19px rgba(0, 0, 0, 0.75);
  .activeSuggestion {
    background: rgba(252, 39, 121, 0.08);
    .multiline-elpisses {
      &:before {
        background: rgb(251 238 244);
      }
      &:after {
        background: rgb(251 238 244);
      }
    }
  }
`;
const Title = styled.div`
  color: #fff;
  background: #636363;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-top: 0;
  height: 29px;
  ${({ theme }) => theme.typography.subTitleMedium};
`;

const SearchSuggestions = ({
  pageType,
  searchField = '',
  maxSearchHistoryCount,
  trendingSearchesToShow,
  searchSuggestionIcons,
  fetchTrendingSearches,
  resetSearch,
  showScreen,
  productSuggestions = [],
  otherSuggestions = [],
}: SearchSuggestionProps) => {
  let [activeOption, setActiveOption] = useState(0);
  const suggestionChanged = useRef(false);
  const dispatch = useDispatch();
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [showIntialElement, setIntialElement] = useState(true);

  /*  Logic to send retina event,
      whenever search input box is clicked
  */
  useEffect(() => {
    if (showScreen) {
      sendSingleEvent(getSearchInputBoxClickEvent());
    }
  }, [showScreen]);

  /*  Logic to send retina event,
      whenever api call made and autocomplete list changes
  */
  useEffect(() => {
    if (
      searchField &&
      searchField.length > 0 &&
      otherSuggestions &&
      otherSuggestions.length > 0
    ) {
      sendSingleEvent(
        getSuggestionsLoadEvent({
          searchTerm: searchField,
          autoSuggestionsList: otherSuggestions,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherSuggestions]);

  // FIXME: move the trending search logic to the Trending Search component
  useEffect(() => {
    if (trendingSearchesToShow > 0) {
      setLoadingSuggestions(true);
      fetchTrendingSearches().then(() => {
        setLoadingSuggestions(false);
      });
    }

    return () => {
      resetSearch();
    };
  }, [fetchTrendingSearches, resetSearch, trendingSearchesToShow]);

  const handleClick = (suggestion: SearchSuggestion, position: number) => {
    handleSearchSuggestionForHistory(suggestion, maxSearchHistoryCount);
    handleSearchSuggestionClick(suggestion, pageType, searchField, position);
    sendSingleEvent(
      getSuggestionsClickEvent({
        searchTerm: searchField,
        clickPosition: position - 1,
        selectedItem: suggestion,
        autoSuggestionsList: otherSuggestions,
      })
    );
  };

  const handleTrendingSearchClick = (
    suggestion: TrendingSearch,
    position: number
  ) => {
    const modifiedSuggestion: SearchSuggestion = {
      ...suggestion,
      id: position.toString(),
    };
    modifiedSuggestion.listItemType = searchTypes.TRENDING;
    handleSearchSuggestionForHistory(modifiedSuggestion, maxSearchHistoryCount);
    handleSearchSuggestionClick(
      modifiedSuggestion,
      pageType,
      searchField,
      position
    );
  };

  const handleKeyPress = useCallback(
    (e: any) => {
      let Queries = document.getElementsByClassName('suggestionQuery');
      const elements = Object.values(Queries) || [];
      const isValidOption =
        elements?.length &&
        activeOption < elements?.length &&
        activeOption >= 0;
      const isDownKey = e.code === 'ArrowDown';
      const isUpKey = e.code === 'ArrowUp';
      if (showIntialElement && (isDownKey || isUpKey)) {
        setActiveOption(0);
        setIntialElement(false);
      } else if (activeOption < elements?.length - 1 && isDownKey) {
        setActiveOption(++activeOption);
      } else if (activeOption !== 0 && isUpKey) {
        setActiveOption(--activeOption);
      }
      if (isValidOption && (isDownKey || isUpKey)) {
        updateSearchField(elements[activeOption]?.dataset?.value)(dispatch);
        elements[activeOption]?.classList.add('activeSuggestion');
        elements[activeOption + 1]?.classList.remove('activeSuggestion');
        elements[activeOption - 1]?.classList.remove('activeSuggestion');
      }
    },
    [activeOption, showIntialElement, dispatch]
  );

  useEffect(() => {
    if (showScreen) {
      window.addEventListener('keydown', handleKeyPress);
    } else {
      setIntialElement(true);
      window.removeEventListener('keydown', handleKeyPress);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress, showScreen]);

  useEffect(() => {
    if (!searchField) {
      resetSuggestions(dispatch);
    } else if (
      searchField &&
      (otherSuggestions?.length || productSuggestions?.length)
    ) {
      suggestionChanged.current = true;
    }
    // Reset active option when there is nothing in search field
    if (
      !searchField &&
      !otherSuggestions?.length &&
      !productSuggestions?.length
    ) {
      setActiveOption(0);
    }
  }, [
    dispatch,
    otherSuggestions?.length,
    productSuggestions?.length,
    searchField,
  ]);

  useEffect(() => {
    if (
      suggestionChanged?.current &&
      (otherSuggestions?.length || productSuggestions?.length)
    ) {
      setActiveOption(0);
      suggestionChanged.current = false;
    }
  }, [otherSuggestions?.length, productSuggestions?.length]);

  useEffect(() => {
    if (!showScreen) {
      setActiveOption(0);
    }
  }, [showScreen]);

  return (
    <>
      <Header focused={showScreen} />
      {showScreen ? (
        <Box>
          {searchField.length ? (
            <>
              <Suggestions
                handleClick={handleClick}
                suggestions={otherSuggestions}
                searchField={searchField}
                searchSuggestionIcons={searchSuggestionIcons}
              />
              {productSuggestions && productSuggestions?.length > 0 && (
                <Title>Popular Products</Title>
              )}
              <Suggestions
                handleClick={handleClick}
                suggestions={productSuggestions}
                searchField={searchField}
                searchSuggestionIcons={searchSuggestionIcons}
              />
            </>
          ) : (
            <>
              <History
                handleClick={handleClick}
                searchSuggestionIcons={searchSuggestionIcons}
              />
              {loadingSuggestions && <Loading items={trendingSearchesToShow} />}
              <Trending handleClick={handleTrendingSearchClick} />
            </>
          )}
        </Box>
      ) : null}
    </>
  );
};

export default SearchSuggestions;
