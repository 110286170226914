import React, { useEffect } from 'react';
import styled from 'App/styled';
import Brand from './LeftNav/Brand';

const Navs = styled.ul`
  display: inline-block;
  vertical-align: middle;
  height: 62px;
  line-height: 62px;
  display: flex;
  padding 0 10px;
  .arrBox_h {
    bottom:19px;
    
  }

  & :hover {
    .arrBox_h {
      visibility: visible;
    }
  }
  li a{
    cursor:pointer;
    text-decoration: none;
  }
`;

const Title = styled.a`
  display: block;
  ${({ theme }) => theme.typography.subTitleSmall};
  line-height: inherit;
  text-transform: uppercase;
  color: #03021a;
  margin: 0 auto;
`;
const Wrapper = styled.div`
  height: 100%;
  position: fixed;
  background: #00000059;
  top: 108px;
  left: 0;
  z-index: 4;
  right: 0;
  overflow: hidden;
  display: none;
`;

const RenderNavigationWithHtml = ({ config, markUp, id }: any) => {
  if (config.type === 'html') {
    if (markUp) {
      if (id === 'categories') {
        return <div dangerouslySetInnerHTML={{ __html: markUp }} />;
      }
      return (
        <Navs
          key={id}
          className="HeaderNav"
          dangerouslySetInnerHTML={{ __html: markUp }}
          onMouseOut={() => {
            toggleCategory(false);
          }}
          onMouseOver={() => {
            toggleCategory(true);
          }}
        />
      );
    }
    return (
      <Navs
        key={id}
        className="HeaderNav"
      >
        <li id={id}>
          <Title href={config.value}>{config.title}</Title>
        </li>
      </Navs>
    );
  }
  return (
    <Navs
      key={id}
      className="HeaderNav"
    >
      <li id={id}>
        <Title href={config.value}>{config.title}</Title>
      </li>
    </Navs>
  );
};
const scrollHandler = () => {
  let doc = document.documentElement;
  let newScrollPosition =
    (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  newScrollPosition = newScrollPosition || 0;
  const catEle = document.getElementById('category') || {};
  const isCategorySelected = catEle.matches(':hover');

  if (newScrollPosition < 60 || isCategorySelected) {
    hideNav(false);
  } else if (newScrollPosition > 60) {
    hideNav(true);
  }
};

const Navigation = ({ config, data = {} }: any) => {
  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, []);
  return (
    <>
      <Wrapper id="black_layer" />
      {Object.keys(config).map((key) =>
        key === 'categories' ? (
          <Navs
            key={key}
            className="HeaderNav"
          >
            <li>
              <Title
                id="category"
                href="/"
              >
                {key}
              </Title>
              <div
                id="category_arrowUp"
                className="arrBox_h"
                style={{ visibility: 'visible' }}
              ></div>
            </li>
          </Navs>
        ) : key === 'brands' ? (
          <Navs
            key={key}
            className="HeaderNav"
            onMouseOut={() => {
              toggleCategory(false);
            }}
            onMouseOver={() => {
              toggleCategory(true);
            }}
          >
            <li>
              <Title href="/">{key}</Title>
              <div
                id="brand_arrowUp"
                className="arrBox_h"
              ></div>
              <Brand data={data[key]} />
            </li>
          </Navs>
        ) : (
          <RenderNavigationWithHtml
            config={config[key]}
            markUp={data[key] || ''}
            id={key}
          />
        )
      )}
    </>
  );
};
const hideNav = (hide: boolean) => {
  document.getElementById('category_navigation').style.display = hide
    ? 'none'
    : 'block';
  document.getElementById('category_arrowUp').style.visibility = hide
    ? 'hidden'
    : 'visible';
};
const toggleCategory = (hide: boolean, isCat: boolean) => {
  if (isCat) {
    const catEle = document.getElementById('category');
    const isCategorySelected = catEle.matches(':hover');
    if (isCategorySelected) {
      hideNav(false);
      return;
    }
    hideNav(true);
    return;
  }
  document.getElementById('category_arrowUp').style.visibility = hide
    ? 'hidden'
    : 'visible';
  document.getElementById('black_layer').style.display = hide
    ? 'block'
    : 'none';
};

export { Navigation, RenderNavigationWithHtml };
